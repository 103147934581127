'use client'
import { useState } from 'react'
import useToggle from './useToggle'

export default function useModal() {
  const [isOpen, toggleModal] = useToggle()

  const [isModal, setIsModal] = useState<boolean>(false)

  const [size, setSize] = useState<'sm' | 'lg' | 'xl'>()
  const [className, setClassName] = useState<string>('')
  const [scroll, setScroll] = useState<boolean>(false)

  // Opens large modal
  function openModalWithSize(size: 'sm' | 'lg' | 'xl') {
    setSize(size)
    setClassName('')
    setScroll(false)
    toggleModal()
    setIsModal(true)
  }

  // Opens modal with custom class
  function openModalWithClass(className: string) {
    setClassName(className)
    setScroll(false)
    toggleModal()
  }

  // Opens large modal
  function openModalWithScroll() {
    setScroll(true)
    // setSize('lg');
    setClassName('')
    toggleModal()
  }

  return {
    isModal,
    setIsModal,
    isOpen,
    size,
    className,
    scroll,
    toggleModal,
    openModalWithSize,
    openModalWithClass,
    openModalWithScroll,
  }
}
