'use client'
import RangeDatepicker from '@/components/common/RangeDatepicker'
import { Button } from '@/components/ui/button'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@/components/ui/popover'
import moment from 'moment'
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectValue,
  SelectGroup,
  SelectItem,
} from '@/components/ui/select'
import { useMainFilterContext } from '@/components/pages/filter/context/MainFilterContext'
import { useEffect, useState } from 'react'
import { clsx } from 'clsx'
import { useTranslations } from 'next-intl'
import { enUS } from 'date-fns/locale'
import { useLocale } from 'next-intl'
import { useDetailCarContext } from '@/components/pages/car/detail/DetailContext'
import { RangeTime } from '@/constants'

type FilterDatePopoverProps = {
  type: 'start' | 'end'
  view?: 'home' | 'detail'
}

export default function FilterDatePopover({
  type,
  view,
}: FilterDatePopoverProps) {
  const locale = useLocale()
  const t = useTranslations('home.hero')

  const { filterValue, setFilterValue } = useMainFilterContext()
  const { detailCar, pricingUse, setPricingUse, listPricing } =
    useDetailCarContext()
  const [open, setOpen] = useState<boolean>(false)
  const date =
    type == 'start'
      ? moment(
          `${filterValue.start_date} ${filterValue.start_time}`,
          'YYYY-MM-DD HH:mm'
        )
      : moment(
          `${filterValue.end_date} ${filterValue.end_time}`,
          'YYYY-MM-DD HH:mm'
        )
  const [dayDiff, setDayDiff] = useState<number>(1)

  const setTimeStartDate = (value: string) => {
    setFilterValue({
      ...filterValue,
      start_time: value,
    })
  }

  const setTimeEndDate = (value: string) => {
    setFilterValue({
      ...filterValue,
      end_time: value,
    })
  }

  const setStoragePricingUse = (pricingId: string) => {
    setFilterValue({
      ...filterValue,
      pricing_id: parseInt(pricingId),
    })
    const pricingUse = listPricing.find(
      (pricing: any) => pricing.id === parseInt(pricingId)
    )
    localStorage.setItem('pricing_use', JSON.stringify(pricingUse))
    setPricingUse(pricingUse)
  }

  const handDateDiff = () => {
    if (filterValue.start_date === '' && filterValue.end_date === '') {
      setDayDiff(0)
      return
    }

    if (filterValue.start_time === '' && filterValue.end_time != '') {
      setDayDiff(1)
      return
    }

    if (filterValue.start_time != '' && filterValue.end_time === '') {
      setDayDiff(1)
      return
    }

    // Khởi tạo thời gian bắt đầu và kết thúc
    const startTime = moment(
      `${filterValue.start_date} ${filterValue.start_time}`,
      'YYYY-MM-DD HH:mm'
    )
    const endTime = moment(
      `${filterValue.end_date} ${filterValue.end_time}`,
      'YYYY-MM-DD HH:mm'
    )

    // Tính toán sự chênh lệch giữa hai khoảng thời gian bằng giờ
    const hoursDiff = endTime.diff(startTime, 'hours')

    // Tính toán số ngày thuê
    let daysDiff = 0
    if (pricingUse?.hour > 0) {
      daysDiff = Math.ceil(hoursDiff / pricingUse.hour)
    }

    setDayDiff(daysDiff)
    setFilterValue({
      ...filterValue,
      hour: hoursDiff,
    })
  }

  const handeSubmit = () => {
    setOpen(false)
  }

  const filterPricing = () => {
    if (detailCar) {
      return listPricing.filter((pricing: any) => {
        let check = false
        detailCar?.pricing_cars?.forEach((item: any) => {
          if (item?.pricing?.id === pricing?.id) {
            check = true
          }
        })

        return check
      })
    }
    return listPricing
  }

  useEffect(() => {
    handDateDiff()
  }, [
    filterValue.start_date,
    filterValue.end_date,
    filterValue.start_time,
    filterValue.end_time,
    pricingUse,
  ])

  return (
    <Popover open={open}>
      <PopoverTrigger>
        <PopoverToggleDateFilter date={date} view={view} setOpen={setOpen} />
      </PopoverTrigger>
      <PopoverContent align="center" className="mb-8" side="top">
        <div className="py-5 px-6 bg-white rounded-3xl shadow-md">
          <RangeDatepicker locale={locale == 'en' ? enUS : ''} />
          <div className="w-full pb-6 flex items-center gap-x-2.5">
            <div className="w-full">
              <label htmlFor="start-date">{t('gio_nhan')}</label>
              <Select
                defaultValue={filterValue.start_time}
                onValueChange={(value) => setTimeStartDate(value)}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder={t('gio_nhan_placeholder')} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {RangeTime.filter(
                      (t) =>
                        filterValue.start_date !== filterValue.end_date ||
                        t < filterValue.end_time
                    ).map((time) => (
                      <SelectItem key={time} value={time}>
                        {time}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center justify-center pt-4">-</div>
            <div className="w-full">
              <label htmlFor="end-date">{t('gio_tra')}</label>
              <Select
                defaultValue={filterValue.end_time}
                onValueChange={(value) => setTimeEndDate(value)}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder={t('gio_tra_placeholder')} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {RangeTime.filter(
                      (t) =>
                        filterValue.start_date !== filterValue.end_date ||
                        t > filterValue.start_time
                    ).map((time) => (
                      <SelectItem key={time} value={time}>
                        {time}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center justify-center pt-4">-</div>
            <div className="w-full">
              <label htmlFor="end-date">{t('gia_thue')}</label>
              <Select
                defaultValue={pricingUse?.id}
                onValueChange={(value) => setStoragePricingUse(value)}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder={t('gia_thue_placeholder')} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {filterPricing().map((pricing: any) => (
                      <SelectItem key={pricing?.id} value={pricing?.id}>
                        {pricing.name}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="flex items-end justify-between pt-6 border-t">
            <div className="font-medium text-primary">
              <p>
                {`${moment(`${filterValue.start_date} ${filterValue.start_time}`, 'YYYY-MM-DD HH:mm').format('DD/MM, HH:mm')} 
                -
                 ${moment(`${filterValue.end_date} ${filterValue.end_time}`, 'YYYY-MM-DD HH:mm').format('DD/MM, HH:mm')}`}
              </p>
              <div className="flex flex-row items-center gap-x-2">
                <p>
                  {t('so_ngay_thue', {
                    dayDiff: dayDiff,
                    unit: pricingUse?.name,
                  })}
                </p>
                <div className="cursor-pointer">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2150_23858)">
                      <path
                        d="M6.06004 5.99992C6.21678 5.55436 6.52614 5.17866 6.93334 4.93934C7.34055 4.70002 7.8193 4.61254 8.28483 4.69239C8.75035 4.77224 9.17259 5.01427 9.47676 5.3756C9.78093 5.73694 9.94741 6.19427 9.94671 6.66659C9.94671 7.99992 7.94671 8.66659 7.94671 8.66659M8.00004 11.3333H8.00671M14.6667 7.99992C14.6667 11.6818 11.6819 14.6666 8.00004 14.6666C4.31814 14.6666 1.33337 11.6818 1.33337 7.99992C1.33337 4.31802 4.31814 1.33325 8.00004 1.33325C11.6819 1.33325 14.6667 4.31802 14.6667 7.99992Z"
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2150_23858">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-x-4">
              <Button onClick={() => setOpen(false)} variant="outline">
                {t('huy')}
              </Button>
              <Button
                onClick={handeSubmit}
                className="font-semibold text-base text-white bg-brand-solid hover:bg-brand-solid py-2.5	px-4 rounded-lg"
              >
                {t('ap_dung')}
              </Button>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}

type PopoverToggleDateFilterProps = {
  date: moment.Moment
  view?: 'home' | 'detail'
  setOpen?: (open: boolean) => void
}

function PopoverToggleDateFilter({
  date,
  view,
  setOpen,
}: PopoverToggleDateFilterProps) {
  return (
    <div
      onClick={() => (setOpen ? setOpen(true) : '')}
      className={clsx(
        'text-base text-base-gray-900',
        view == 'detail' ? 'font-bold' : 'font-semibold pl-5'
      )}
    >
      {view == 'detail'
        ? date.format('HH:mm, DD/MM/YYYY')
        : `${date.format('DD/MM, HH:mm')}`}
    </div>
  )
}
