'use client'
import { addDays } from 'date-fns'
import * as React from 'react'
import { DateRange } from 'react-day-picker'
import { Calendar } from '@/components/ui/calendar'
import { cn } from '@/lib/utils'
import { useMainFilterContext } from '@/components/pages/filter/context/MainFilterContext'
import moment from 'moment'
import { vi } from 'date-fns/locale'

export default function RangeDatepicker({
  className,
  locale,
}: {
  className?: React.HTMLAttributes<HTMLDivElement>
  locale?: any
}) {
  const { filterValue, setFilterValue } = useMainFilterContext()

  const [date, setDate] = React.useState<DateRange | undefined>({
    from: filterValue.start_date,
    to: filterValue.end_date,
  })

  const handleSetDate = (date: DateRange | undefined) => {
    setDate(date)
    if (!date) {
      setFilterValue({
        ...filterValue,
        start_date: '',
        end_date: '',
      })
      return
    } else {
      setFilterValue({
        ...filterValue,
        start_date: moment(date.from).format('YYYY-MM-DD'),
        end_date: moment(date.to).format('YYYY-MM-DD'),
      })
    }
  }

  return (
    <div className={cn('grid gap-2', className)}>
      <Calendar
        defaultMonth={date?.from}
        initialFocus
        mode="range"
        numberOfMonths={2}
        onSelect={(date) => handleSetDate(date)}
        selected={date}
        showOutsideDays
        locale={locale ? locale : vi}
      />
    </div>
  )
}
