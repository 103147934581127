import Image from 'next/image'
import { StarSvg } from '@/icons-svg/SvgIcons'
import { Link } from '@/navigation'
import { FeatureType } from '@/constants'
import { buildCarName } from '@/utils'

function ItemCard({
  car,
  isHomepage = false,
}: {
  car: any
  isHomepage?: boolean
}) {
  return (
    <div className="border border-base-gray-200 shadow-card-item rounded-2xl">
      <Link href={'/car/' + car.id}>
        <div className="rounded-2xl">
          <Image
            alt={'Thue xe tự lái'}
            className="rounded-t-2xl w-full max-h-64 min-h-[185px] oject-cover"
            src={
              car.album && car.album.length > 0 ? car.album[0].album_url : ''
            }
            loading="lazy"
            width={0}
            height={0}
            sizes="100vw"
            style={isHomepage ? { height: '185px' } : { height: 'auto' }}
          />
        </div>
        <div className="p-5">
          <div className="flex justify-between h-full">
            <h2 className="font-bold text-base text-base-gray-900 uppercase">
              {buildCarName(car)}
            </h2>
            <div className="flex items-center">
              <StarSvg />
              <span className="text-xs text-base-gray-900 font-semibold ml-1">
                4.8
              </span>
            </div>
          </div>
          <div className="py-4 border-b flex items-center justify-start flex-wrap gap-2">
            {car?.feature_cars && car?.feature_cars.length > 0
              ? car?.feature_cars
                  ?.filter((item: any) => {
                    return item?.feature?.type === FeatureType.OPTION
                  })
                  .map((feature: any) => (
                    <div
                      key={feature.id}
                      className="flex items-center rounded-md py-1 px-2 border border-base-gray-200 text-base-gray-700 font-medium text-xs"
                    >
                      <Image
                        width={16}
                        height={16}
                        src={`https://imagedelivery.net/P95wiQ_AewXoXkc8aF8kNA/${feature.feature.icon}/icons`}
                        alt=""
                      />
                      <span className="ml-3">
                        {feature?.feature_option?.option_value}
                      </span>
                    </div>
                  ))
              : ''}
          </div>
          <div className="py-4 flex justify-start items-center">
            {car.pricing_cars && car.pricing_cars.length > 0 ? (
              <p className="text-base-gray-900 text-xl font-bold py-2">
                {car.pricing_cars[0].price.toLocaleString('vi')}đ
              </p>
            ) : (
              ''
            )}
            <span className="text-xs text-base-gray-500 ml-1">/ngày</span>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default ItemCard
